//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageHeader from '@/components/page-header';
import HeaderFormItem from '@/components/page-header/header-form-item';
export default {
    components: { HeaderFormItem, PageHeader },
    inject: ['hideLoading', 'showLoading'],
    computed: {
        roleList() {
            return this.$store.state['roleManagement'].roleList;
        },
        roleListTotal() {
            return this.$store.state['roleManagement'].roleListTotal;
        },
    },
    data() {
        return {
            queryParams: {
                pageSize: 10,
                pageNum: 1,
                roleName: '',
            },
        };
    },
    created() {
        this.getRoleList();
    },
    // mounted() {
    //     this.hideLoading();
    // },
    methods: {
        //顶部查询
        search() {
            this.queryParams.pageNum = 1;
            this.getRoleList();
        },
        //查询角色信息
        async getRoleList() {
            this.showLoading();
            let res = await this.$store.dispatch('roleManagement/getAllRoleList', this.queryParams);
            this.hideLoading();
            if (res.code !== 200) {
                this.$notify({
                    title: res.code,
                    message: res.msg,
                    type: 'error',
                    duration: 2000,
                    position: 'bottom-right',
                });
            }
        },

        //增加角色信息
        addHandler() {
            this.$emit('showAdd', true);
        },

        //修改角色信息
        modifyHandler(item) {
            this.$store.dispatch('roleManagement/changeActiveItem', item);
            this.$emit('showModify', true);
        },
        //删除角色信息
        removeMenuItem(item) {
            this.$confirm(`是否删除该角色(${item.roleName})?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
            })
                .then(async () => {
                    this.showLoading();
                    let changeRes = await this.$store.dispatch('roleManagement/removeRoleInformation', { id: item.id });
                    this.hideLoading();
                    let type = 'error';
                    if (changeRes.code === 200) {
                        this.search();
                        type = 'success';
                    }
                    this.$notify({
                        title: changeRes.code,
                        message: changeRes.msg,
                        type: type,
                        duration: 2000,
                        position: 'bottom-right',
                    });
                })
                .catch(() => {});
        },
        //授权
        authorizeHandler(item) {
            this.$store.dispatch('roleManagement/changeActiveItem', item);
            this.$emit('showAuthorization', true);
        },
        //分配菜单
        assignMenuHandler(item) {
            this.$store.dispatch('roleManagement/changeActiveItem', item);
            this.$emit('showAssignMenu', true);
        },
        //选择每一页的数据量
        handleSizeChange(val) {
            this.queryParams.pageNum = 1;
            this.queryParams.pageSize = val;
            this.getRoleList();
        },

        //选择当前页页码
        handleCurrentChange(val) {
            this.queryParams.pageNum = val;
            this.getRoleList();
        },
    },
};
