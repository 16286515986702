//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            addForm: {
                id: null,
                roleName: '',
                remark: '',
                status: 1,
            },
            rules: {
                roleName: [
                    {required: true, message: '请输入角色名称'}
                ],
                status: [
                    {required: true, message: '请选择状态'}
                ]
            },
        };
    },
    computed: {
        activeItemRole() {
            return this.$store.state['roleManagement'].activeItem;
        }
    },
    inject: ['showLoading', 'hideLoading'],
    async created() {
        for (let i in this.addForm) {
            this.addForm[i] = this.activeItemRole[i];
        }
    },
    methods: {
        goList() {
            this.$emit('hideModify', false);
        },

        //提交修改角色信息数据
        submitForm() {
            this.$refs["addForm"].validate(async (valid) => {
                if (valid) {
                    this.showLoading();
                    let addRes = await this.$store.dispatch(
                        "roleManagement/modifyRoleInformation", this.addForm
                    );
                    this.hideLoading();
                    let type = 'error';
                    if (addRes.code === 200) {
                        type = 'success';
                        this.goList();
                        this.$parent.$parent.$refs.list.search();
                    }
                    this.$notify({
                        title: addRes.code,
                        message: addRes.message,
                        type: type,
                        duration: 2000,
                        position: "bottom-right"
                    });
                }
            });
        },
        resetForm() {
            this.$refs["addForm"].resetFields();
        }
    }
}
