//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "./list";
import Add from "./add";
import Modify from "./modify";
import AssignMenu from './assign-menu';

export default {
	components:{
		List,
        Add,
        Modify,
        AssignMenu,
	},
    data(){
        return{
            modifyVisible: false,
            addVisible:false,
            assignMenuVisible: false,
        }
    },
    methods:{
        handlerModify(bool){
            this.modifyVisible = bool;
        },
        handlerAdd(bool){
            this.addVisible = bool;
        },
        handlerAssignMenu(bool) {
            this.assignMenuVisible = bool;
        }
    }
}
