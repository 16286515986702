//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'assign-menu',
    inject: ['showLoading', 'hideLoading'],
    data() {
        return {
            multipleSelection: [],
            roleId: null,
            haveSelectIds: [],
            authorizationProps: {
                label: 'menuName',
                children: 'children'
            },
            haveSelect: null,
            treeDataSelect: [],

        }
    },
    computed: {
        activeItemRole() {
            return this.$store.state['roleManagement'].activeItem;
        },
        allMenuList() {
            return this.$store.state["menuManagement"].menuList;
        }
    },
    async created() {
        this.showLoading();
        await this.$store.dispatch('menuManagement/getMenuList');
        // 角色拥有的权限id
        let res = await this.$store.dispatch('roleManagement/getAssignMenuList',
            [this.activeItemRole.id]);
        // 获取所有的子节点id
        this.getChildrenId(this.allMenuList);
        // 求两个的交集，舍去父节点(子节点勾选上会自动勾选父节点)
        let owns = [...new Set(res.data)]
        let duplicatedValues = owns.filter(
            item => this.treeDataSelect.includes(item)
        );
        this.$refs.menuTree.setCheckedKeys(duplicatedValues);
        this.hideLoading();
    },
    methods: {
        //批量分配
        async setMenuToRoleHandle() {
            let checkedNodes1 = this.$refs.menuTree.getHalfCheckedKeys();
            // 全选择数组
            let checkedNodes2 = this.$refs.menuTree.getCheckedKeys();
            let checkedNodes = [...checkedNodes1, ...checkedNodes2];
            this.showLoading();
            let addRes = await this.$store.dispatch("roleManagement/setMenuToRole", {
                menuIds: checkedNodes,
                roleId: this.activeItemRole.id,
            });
            this.hideLoading();
            let type = 'error';
            if (addRes.code === 200) {
                type = 'success';
                this.goList();
            }
            this.$notify({
                title: '提示',
                message: addRes.msg,
                type: type,
                duration: 2000,
                position: "bottom-right",
            });
        },
        goList() {
            this.$emit('hideAssignMenu', false);
        },
        /**
         * 递归获取id
         */
        getChildrenId(val) {
            val.forEach(item => {
                if (item.children && item.children.length > 0) {
                    this.getChildrenId(item.children);
                } else {
                    this.treeDataSelect.push(item.id);
                }
            })

        }
    }
}
