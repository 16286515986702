//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    computed: {},
    data() {
        return {
            addForm: {
                roleName: '',
                remark: '',
                status: 1,
            },
            rules: {
                roleName: [
                    {required: true, message: '请输入角色名称'}
                ],
                status: [
                    {required: true, message: '请选择状态'}
                ]
            },
        };
    },
    inject: ['showLoading', 'hideLoading'],
    methods: {
        goList() {
            this.$emit('hideAdd', false);
        },

        //提交新增角色信息
        submitForm() {
            this.$refs["addForm"].validate(async (valid) => {
                if (valid) {
                    this.showLoading();
                    let addRes = await this.$store.dispatch(
                        "roleManagement/addRoleInformation", this.addForm
                    );
                    this.hideLoading();
                    let type = 'error';
                    if (addRes.code === 200) {
                        type = 'success';
                        this.goList();
                        this.$parent.$parent.$refs.list.search();
                    }
                    this.$notify({
                        title: addRes.code,
                        message: addRes.message,
                        type: type,
                        duration: 2000,
                        position: "bottom-right"
                    });
                }
            });
        },
        resetForm() {
            this.$refs["addForm"].resetFields();
        },
    }
}
